<template>
  <div class="grid">
    <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
    <div class="col-12 sticky">
      <div id="custom_card" class="card">
        <!-- <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading> -->
        <!-- <Toast /> -->
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
              <p style="color: #6b719b; font-size: 20px; font-weight: 600">
                {{ $t('ticket_details') }}
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3">
              <!-- <Button class="p-button-warning" iconPos="right" label="Edit Ticket" icon="pi pi-pencil"
                v-tooltip.bottom="'Edit Ticket'" @click="Edit_Ticket" size="small"
                style="border-color: #006b00!important;background-color: #006b00!important;" /> -->
              <Button   :label="$t('back')" 
              v-tooltip.bottom="$t('back')"  @click="Goback" size="small"
              style="
        padding: 5px 10px; 
        font-size: 16px; 
        line-height: 1.2; 
        box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549); 
        border-color: #00b0b0!important; 
        background-color: #00b0b0!important;" 
     />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-9">
          <div class="p-2 text-white bg-success ">
            <!-- <p style="color: white; font-size: 1rem; font-weight: 400">
              {{ product.ticket_no }}  {{ row_data[0].title }}
            </p> -->
            <!-- <p style="color: white; font-size: 1rem; font-weight: 400; display: flex; justify-content: space-between;">
              <span>{{ product.ticket_no }}</span>
            
            </p>
            <div style="text-align: right;width: 100%;">
              <Button icon="pi pi-comment" :label="$t('reply_comment_status')" v-tooltip.bottom="$t('post_reply_comment')" 
                @click="Post_Reply_view" class="w-auto" style="font-size:0.875rem;font-weight: 400;margin-left:auto;margin-right:0;"
                size="small" id="b_post" />
              </div> -->
              <div style="display: flex; justify-content: space-between; align-items: center; color: white; font-size: 1rem; font-weight: 400;">
            <span>{{ product.ticket_no }}</span>
            <Button 
              icon="pi pi-comment" 
              :label="$t('reply_comment_status')" 
              v-tooltip.bottom="$t('post_reply_comment')" 
              @click="Post_Reply_view" 
              class="w-auto" 
              style="font-size: 0.875rem; font-weight: 400; margin-left: auto;" 
              size="small" 
              id="b_post" 
            />
          </div>

            
        
          </div>
         
          
          <Card v-if="show_post_reply">
            <template #content>
              <div class="col-12">
                <div class="p-fluid formgrid grid">
                  <!-- <div class="field col-6 md:col-6">

                    <Dropdown v-model="comment_type" :options="comment_type_dropdown" optionLabel="name"
                      placeholder="Select Comment Type" />
                  </div> -->
                  <div class="field col-6 md:col-6">
                    <Dropdown id="ticket_status" v-model="ticket_status_selected" :options="ticket_status_dropdown"
                      :value="ticket_status_dropdown._id" optionLabel="marathi" filter
                      :placeholder="$t('select_ticket_status')"  optionValue="_id" @change="handleTicketStatusChange">
                    </Dropdown>
                  </div>
                  <div class="field col-6 md:col-6" v-if="showRejectDropdown">
                    <Dropdown id="ticket_reject" v-model="ticket_reject_selected" :options="ticket_reject_dropdown"
                      :value="ticket_reject_dropdown._id" optionLabel="name" filter
                       :placeholder="$t('select_rejection_reason')" ></Dropdown>
                  </div>
                  <div class="field col-12 md:col-12">
                    <label class="mb-2" style=" font-size: 16px; font-weight: 600"> {{ $t('public_comm') }}</label>
                    <Editor v-model="post_public_comment" editorStyle="height: 200px" />
                  </div>

                  <div class="field col-12 md:col-12">
                    <label class="mb-2" style=" font-size: 16px; font-weight: 600">{{ $t('internal_comm') }}</label>
                    <Editor v-model="post_internal_comment" editorStyle="height: 200px" />
                  </div>


                  <div class="field col-12 md:col-2">
                    <Button :label="$t('post_reply')" 
                            v-tooltip.bottom="$t('post_reply')" 
                          @click="Post_Reply()"
                      class="p-button-warning btn_yellow" />
                  </div>&nbsp;
                  <!-- <div class="field col-12 md:col-2"> -->

                  <!-- <FileUpload class="p-button-Secondary btn_Secondary" mode="basic" name="model[]" :auto="true"
                      :custom-upload="true" choose-label="Attachment" @uploader="handleFileUpload($event)" /> -->
                  <!-- </div> -->
                  <div class="field col-12 md:col-2">
                    <Button :label="$t('Cancel')" v-tooltip.bottom="$t('Cancel')"  @click="cancel_Post_Reply_main"
                      class="p-button-danger btn_red" />
                  </div>
                </div>
              </div>
            </template>
          </Card>&nbsp;&nbsp;&nbsp;
          <!-- <SelectButton v-model="selectButtonValue1" :options="selectButtonValues1" optionLabel="name" /> -->


         
                <div class="card" 
     style="width: 100%; margin-right: 10px;" 
     
   >
                      <h3 v-if="row_data[0]"
                        style="text-align: center; padding: 7px; background-color: #f8f9fa; border: 1px solid #ddd; border-radius: 5px; color: #333; font-size: 18px;">
                        <!-- Public / Internal Comments -->
                      <!-- {{ $t('ticket_history') }} -->
                      {{ row_data[0].title }}
                      </h3>
                    
                      <div v-if ="comments.length>0">
                        <div v-for="(entry, index) in comments" :key="index" class="timeline-entry" :data-type="entry.comment_type"  >
                        <div class="timeline-time">{{ D_M_Y_formatDate(entry.created_time) }}<br>{{ entry.created_date }}</div>
                        <div class="timeline-comment">
                            <div class="timeline-marker"></div>
                            <div class="comment-box" :style="{ 
    width: '100%', 
    marginRight: '8px', 
    borderLeft: entry.comment_type === 'Public' ? '5px solid skyblue' : '5px solid grey' 
}">                                <div class="comment-header">{{ entry.status_name }}</div> 
                                <!-- <p class="m-0" style=" font-size: 16px; font-weight: 600">Description:</p>
              <p class="m-0" style=" font-size: 16px; font-weight: 600">
                {{ convertHtmlToText(row_data[0].description) }}
              </p> -->
                                <div class="comment-header">{{ entry.reject_reason }}</div> 
                                <p >
                      {{ entry.comment ? convertHtmlToText(entry.comment) : "" }}
                    </p>
 <!-- <p class="comment-header">{{ entry.username }}</p> -->
                                <div class="comment-meta">{{ entry.comment_type.charAt(0).toUpperCase() + entry.comment_type.slice(1) }} - {{ entry.username }}</div>
                            </div>
                        </div>
                    </div>

                      </div>
                      <div v-else>
                          {{ $t('no_comm') }}
                      </div>
                      <!-- {{ comments }} -->
                      
                   


            <!-- {{ comments }} -->



          </div>

        </div>
        <div class="field col-12 md:col-3">
          <div id="custom_card" class="card" v-if="row_data[0]">
            <div class="col-12">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-12">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 800">
                   {{ $t('ticket_details') }}
                  </p>
                </div>
                <div class="field col-12 md:col-12">
                  <!-- <Dropdown id="ticket_status" v-model="ticket_status_selected" :options="ticket_status_dropdown"
                    :value="ticket_status_dropdown._id" optionLabel="marathi" filter @change="
                      update_status(product.ticket_no, ticket_status_selected, product.ticket_status)
                      "></Dropdown> -->

                </div>
              
                <div class="field col-12 md:col-12">
                  <span class="p-float-label">
                    <Dropdown id="priority_id" v-model="priority_selected" :options="ticket_priority_dropdown"
                      :value="ticket_priority_dropdown" optionLabel="name" filter @change="
                        update_priority(priority_selected.name, priority_selected, ticket_status_selected)
                        " />

                  </span>
                </div>
                <div  class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400;">
                   {{ $t('citizen_name') }}:
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500;word-wrap: break-word">
                    {{ row_data[0].customer_name }}
                  </p>
                </div>
                <div  class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400;">
                   {{ $t('status') }}:
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500;word-wrap: break-word">
                    {{ row_data[0].ticket_status_name   }}
                   
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400;">
                    {{ $t('email') }}:
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500;word-wrap: break-word">
                    {{ row_data[0].customer_email }}
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    {{ $t('dept') }}:
                  </p>

                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500;word-wrap: break-word">
                    {{ row_data[0].ticket_category_name }}
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    {{ $t('ward_no') }}:
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500;word-wrap: break-word">
                    {{ row_data[0].ward_no }}
                  </p>

                </div>
                <!-- <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    Priority:
                  </p>
                </div>
                <div class="field col-12 md:col-8">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 700;word-wrap: break-word">
                      {{ row_data[0].priority_name }}
                  </p>

                </div> -->
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                 {{ $t('emp_name') }}:
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500">
                    <!-- {{
                      row_data[0].agent_details == ""
                        ? "N/A"
                        : row_data[0].agent_details[0].agent_name
                    }} -->
                    {{
                       row_data[0].agent_details && row_data[0].agent_details.length>0
                        ? row_data[0].agent_details[0].agent_name
                        : ""
                    }}
                  </p>

                  <!-- <AvatarGroup class="mb-2">
                    <Avatar v-for="(info, index) in row_data[0].agent_details" :key="index" :image="info.agent_profile"
                      size="small" shape="circle" @click="show_Agent(info, index)" v-tooltip.bottom="info.agent_name"
                      style="cursor: pointer;" class="zoom"></Avatar>
                  </AvatarGroup> -->
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    {{ $t('created') }}:
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500">
                    {{ D_M_Y_formatDate(row_data[0].created_at) }}
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                   {{ $t('Attachments') }}:
                  </p>
                </div>

                <div class="field col-12 md:col-6">
                  <AvatarGroup class="mb-2">
                    <Avatar v-for="(info, index) in row_data[0].attachments" :key="index" :image="info" size="small"
                      shape="circle" @click="show_Attchment(info, index)" style="cursor: pointer; height: 30px; width:30px"
                      class="zoom">
                    </Avatar>
                  </AvatarGroup>
                </div>
                <!-- <div class="field col-12 md:col-12">
                  <img
                    v-if="row_data[0].attachments"
                    :src="row_data[0].attachments"
                    
                    alt=""
                    class="zoom"
                    style="height: 50px; width: 50px"
                  />
                </div> -->
                <div class="field col-12 md:col-6" v-if="row_data[0].video">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                     {{ $t('video') }}:
                  </p>
                </div>

                <div v-if="row_data[0].video" class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500">
                    <a :href="row_data[0].video" target="_blank">
                      <img style="width:50px" src="https://www.freeiconspng.com/thumbs/video-icon/video-icon-1.png"
                        alt="Video Thumbnail" class="video-thumbnail" />
                    </a>
                  </p>
                </div>

                <div class="field col-12 md:col-12">
                  <!-- <Button
                    id="b_delete"
                    label="Delete Ticket"
                    v-tooltip.bottom="'Delete Ticket'"
                    @click="DeleteTicket"
                    class="p-button-danger p-button-outlined "
                    size="small"
                  /> -->

                  <!-- ------------------ -->

                  <!-- <Button id="b_delete" label="Delete Ticket" v-tooltip.bottom="'Delete Ticket'"
                    @click="delete_toggle(item)" class="p-button-danger p-button-outlined " size="small" /> -->

                  <OverlayPanel ref="del">
                    <div class="flex flex-column gap-3 w-25rem">
                      <h5>  {{ $t('delete_ticket') }}</h5>

                      <div class="col-12">
                        <div class="p-fluid formgrid grid">
                          <div class="field col-12 md:col-12">
                            <span class="p-float-label">
                              <Textarea format="text" v-model="delete_remark">
                      </Textarea>
                              <label> {{ $t('remark') }}</label>
                            </span>
                          </div>

                          <div class="field col-12 md:col-4">
                            <Button :label="$t('delete')" @click="delete_product" class="p-button-primary btn_light_blue" />
                          </div>
                          <div class="field col-12 md:col-4">
                            <Button  :label="$t('Cancel')" @click="Cancel_delete" class="p-button-danger btn_red" />
                          </div>

                        </div>
                      </div>

                    </div>
                  </OverlayPanel>

                  <!-- ------------------ -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ScrollTop />
  </div>
</template>
<script>
import apis from "@/apis";
import axios from "axios";
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
import ColorService from "../service/ColorService";
export default {
  name: "EticketTicketDetails",
  data() {
    return {
      ward_no: '',
      showComment: false,
      delete_remark: "",
      priority_name: "",
      ColorService: null,
      priority_selected: "",
      ticket_priority_dropdown: [],
      color_data: [],
      show_post_reply: false,
      product: [],
      comments: [],
      ticket_status_dropdown: [],
      ticket_reject_dropdown: [],
      showRejectDropdown: false,
      comment_type_dropdown: [{ code: "Public", name: "Public" }, { id: "Internal", name: "Internal" }],
      comment_type: "",
      ticket_status_selected: "", 
      ticket_reject_selected: "",
      row_data: [],
      productService: null,
      isLoadingModel: false,
      fullPage: true,
      post_public_comment: "",
      post_internal_comment: "",
      attachments: [],
      video: "",
      attachments_count: 0,
      user_id: "",
      selectButtonValues1: [
        { name: 'Public Comments', code: '1' },
        { name: 'Internal Comments', code: '2' }
      ],
      selectButtonValue1: { name: "Public Comments", code: "1" },

    };
  },

  computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    },
    combinedComments() {
      // Combine both public and internal comments into one array
      return [
        ...this.row_data[0]?.ticket_comments?.map(comment => ({ ...comment, type: 'public' })) || [],
        ...this.row_data[0]?.internal_ticket_comments?.map(comment => ({ ...comment, type: 'internal' })) || []
      ];
    }
  },
  async mounted() {
    this.ColorService.getColors().then((colors) => {
      this.color_data = colors;

    });
  },
  async created() {
    this.ColorService = new ColorService();
    this.productService = new ProductService();
    this.initFilters();
    this.user_id = localStorage.getItem("id");

    if (localStorage.getItem("TicketDetails")) {
      this.product = await JSON.parse(localStorage.getItem("TicketDetails"));
    }

    //await this.getWardList();
    await this.getPriorityList();
    await this.Get_ticketDetails();
    await this.get_TicketStatusMaster();
    // await this.getTicketRejectReason();
    this.ticket_status_selected = this.product.ticket_status;
    this.priority_selected = this.product.priority_id;

    await localStorage.setItem("TicketDetails", "");
  },
  watch: {
    currentLocale(){
      //  this.getTicketCategoryMaster(this.currentLocale);
      //  this.getTicketStatusMaster(this.currentLocale);
     this.Get_ticketDetails(this.currentLocale);
       //this.getWardList(this.currentLocale);
      this.get_TicketStatusMaster(this.currentLocale);
      //  this.get_list(this.currentLocale);
      //   this.getWardList();
   
     
      
    }

  },
  computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    }
  },
  methods: {
    getLabelClass(status) {
      if (status === 1) return "red-label";
      if (status === 2) return "green-label";
      return "default-label";
    },

    show_comment() {
      this.showComment = true;
    },
    async getPriorityList() {
      var data = {
        //client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
        
      };
      // this.isLoadingModel = true;
      var promise = apis.getPriorityList(data);
      promise.then((response) => {
        // this.isLoadingModel = false;
        this.ticket_priority_dropdown = response.data.data;
      });
    },
    async show_Attchment(info, index) {
      window.open(info);
    },
    async show_Agent(info, index) {
      console.log(info);
      console.log(index);
    },
    async confirmDeleteComment(data, index) {
      this.$confirm.require({
        target: event.currentTarget,
        // message: 'Are you sure you want to Delete comment?',
        message:this.$t('delete_comment'),
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          console.log(data);
          await apis.deleteTicketComment({ id: data._id, user_id: this.user_id,   lang:this.$i18n.locale });
          this.$swal("comment has been deleted sucessfully");
          this.Get_ticketDetails();
        }
      });
    },
    DeleteTicket() {

      this.$confirm.require({
        target: event.currentTarget,
       // message: 'Are you sure you want to Delete ticket?',
        message:  this.$t('confirm_delete_ticket'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {


          var payload =
          {
            "client_id": "65a4f82f6177e69880ece5d6",
            "user_id": localStorage.getItem("id"),
            "ticket_no": this.product.ticket_no,
            lang:this.$i18n.locale

          }

          this.isLoadingModel = true;
          var promise = apis.deleteTicket(payload);
          promise.then((response) => {
            this.isLoadingModel = false;
            if (response.data.status == true) {
              this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
              this.$router.push({ name: "tickets" });
              window.scrollTo({ top: 0, behavior: 'smooth' });

            }
            else {
              this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
            }
          });

        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Cancel', detail: 'You have Cancelled', life: 3000 });
        }
      });


    },


    delete_toggle() {
      this.$refs.del.toggle(event);
    },

    delete_product() {

      if (this.delete_remark == null || this.delete_remark == "" || this.delete_remark == undefined) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail:  this.$t('pleaseEnterRemark'),
          life: 3000,
        });
        return false;
      }
      if (this.delete_remark.length > 1000) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: this.$t('pleaseEnterValidRemark'),
          life: 3000,
        });
        return false;
      }


      else {
        var payload =
        {
          "client_id": "65a4f82f6177e69880ece5d6",
          "user_id": localStorage.getItem("id"),
          "ticket_no": this.product.ticket_no,
          "remark": this.delete_remark,
          lang:this.$i18n.locale
        }

        this.isLoadingModel = true;
        var promise = apis.deleteTicket(payload);
        promise.then((response) => {
          this.isLoadingModel = false;
          if (response.data.status == true) {
            this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
            this.delete_remark = "";
            this.$router.push({ name: "tickets" });
            window.scrollTo({ top: 0, behavior: 'smooth' });

          }
          else {
            this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
          }
        });
      }
    },

    Cancel_delete() {
      // this.temp_product_id="";
      this.delete_remark = "";
      this.$refs.del.hide();
    },

    getcolorcode(name) {
      var name = this.convert_name(name)
      var temp = name.substring(0, 1);
      for (let index = 0; index < this.color_data.length; index++) {
        if (this.color_data[index].name == temp) {
          return this.color_data[index].code;
        }

      }
    },
    convert_name(name) {
      var x = this.ColorService.convertToSN(name);
      return x.toString();

    },

    async edited_Post_Reply(value, index) {
      var details =
      {
        "Edited_comment": this.row_data[0].ticket_comments[index]['edited_comment'],
        "Edited_attachment": this.row_data[0].ticket_comments[index]['edited_attachment']
      }

      console.log("***********************");
      console.log(details);
    },
    async Post_Reply() {
      var data = {

        "ticket_no": this.product.ticket_no,
        "client_id": "65a4f82f6177e69880ece5d6",
        "user_id": localStorage.getItem("id"),
        "public_comment": this.post_public_comment,
        "internal_comment":this.post_internal_comment,
        "attachments": this.attachments,
        //"comment_type": this.comment_type.name,
        "status":this.ticket_status_selected,
        "reject_reason": this.showRejectDropdown ? this.ticket_reject_selected._id : null,
        lang:this.$i18n.locale
      };
      //console.log("data5",data)
      this.isLoadingModel = true;
      var promise = apis.SendTicketComment(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.post_public_comment = "";
        this.post_internal_comment = "";
        this.ticket_reject_selected = "";
        this.ticket_status_selected = "";
        this.show_post_reply = false;
        this.attachments = [];
        this.attachments_count = 0;
        this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
        this.Get_ticketDetails();
        this.get_TicketStatusMaster();
      });
    },
    async Post_Reply_view() {
      this.show_post_reply = true;

    },
    async cancel_Post_Reply_main() {
      this.show_post_reply = false;

    },
    async cancel_Post_Reply(value, index) {
      var data = this.row_data[0].ticket_comments;
      for (let i = 0; i < data.length; i++) {

        data[i]['show_editor'] = false;
        data[i]['edited_comment'] = "";
        data[i]['edited_attachment'] = [];


      }
      this.row_data[0].ticket_comments = data;
    },
    async confirmeditpost(value, index) {
      var data = this.row_data[0].ticket_comments;
      for (let i = 0; i < data.length; i++) {
        if (i === index) {
          data[i]['show_editor'] = true;
          data[i]['edited_comment'] = data[i]['comment'];
          data[i]['edited_attachment'] = data[i]['attachments'];
        } else {
          data[i]['show_editor'] = false;
          data[i]['edited_comment'] = "";
          data[i]['edited_attachment'] = [];
        }


      }
      this.row_data[0].ticket_comments = data;
    },
    async Edit_Ticket() {
      var user_type = localStorage.getItem("user_type");
      await localStorage.setItem("TicketDetails", "");
      await localStorage.setItem(
        "TicketDetails",
        JSON.stringify(this.row_data)
      );
      if (user_type == "Admin") {
        this.$router.push({ name: "AdminEditTicket" });
      } else if (user_type == "Customer") {
        this.$router.push({ name: "CustomerEditTicket" });
      } else if (user_type == "Agent") {
        this.$router.push({ name: "AgentEditTicket" });
      } else {
        await localStorage.setItem("TicketDetails", "");
      }
    },
    convertHtmlToText(html) {
      var temporaryElement = document.createElement("div");
      temporaryElement.innerHTML = html;
      return temporaryElement.textContent || temporaryElement.innerText || "";
    },
    async Goback() {
      this.$router.push({ name: "tickets" });
    },
    D_M_Y_formatDate(date_parm) {
      if (date_parm) {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd + "/" + mm + "/" + yyyy;
        // alert(dd)
        if (dd.toString() != "NaN") {
          console.log(new_date);
          return new_date;
        } else {
          return date_parm;
        }
      }
    },

    // D_M_Y_formatDate(date_parm) {
    //   if (date_parm) {
    //     let date = new Date(date_parm);
    //     let dd = date.getDate();
    //     let mm = date.getMonth() + 1;
    //     let yyyy = date.getFullYear();

    //     let hh = date.getHours();
    //     let min = date.getMinutes();
    //     let ss = date.getSeconds();

    //     // Add leading zeros if needed
    //     dd = dd < 10 ? '0' + dd : dd;
    //     mm = mm < 10 ? '0' + mm : mm;
    //     hh = hh < 10 ? '0' + hh : hh;
    //     min = min < 10 ? '0' + min : min;
    //     ss = ss < 10 ? '0' + ss : ss;

    //     let new_date = `${dd}/${mm}/${yyyy} ${hh}:${min}:${ss}`;
    //     if (dd.toString() !== "NaN") {
    //       console.log(new_date);
    //       return new_date;
    //     } else {
    //       return date_parm;
    //     }
    //   }
    // },


    D_M_Y_formatDate1(date_parm) {
      if (date_parm) {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth();
        let yyyy = date.getFullYear();

        // Months array to convert month number to name
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        let hh = date.getHours();
        let min = date.getMinutes();

        // Add leading zeros if needed
        dd = dd < 10 ? '0' + dd : dd;
        min = min < 10 ? '0' + min : min;

        // Convert hours to 12-hour format
        let period = 'AM';
        if (hh >= 12) {
          period = 'PM';
          if (hh > 12) hh -= 12; // Convert to 12-hour format
        }
        if (hh === 0) hh = 12; // Midnight case (00:00 is 12:00 AM)

        // Format date and time separately
        let formattedDate = `${dd} ${months[mm]} ${yyyy}`;
        let formattedTime = `${hh}:${min} ${period}`;

        return { formattedDate, formattedTime };
      }
    },



    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    // async get_TicketStatusMaster() {
    //   var data = {
    //     client_id: "65a4f82f6177e69880ece5d6",
    //   };
    //   var promise = apis.TicketStatusMaster(data);
    //   promise.then((response) => {
    //     this.ticket_status_dropdown = response.data.data;
    //   });
    // },
    async get_TicketStatusMaster() {
      var data = {
        role_id: localStorage.getItem("role_id"),
        status_id: this.status_id,
        lang:this.$i18n.locale
        
      };
      //console.log("data1",data)
      var promise = apis.getTicketRoleStatusMapping(data);
      promise.then((response) => {
        this.ticket_status_dropdown = response.data.data;
      });
    },
    async getTicketRejectReason() {
      var promise = apis.getTicketRejectionReason();
      promise.then((response) => {
        this.ticket_reject_dropdown = response.data.data;
      });
    },
    handleTicketStatusChange() {
      const selectedStatus = this.ticket_status_dropdown.find(
        (status) => status._id === this.ticket_status_selected
      );
      this.getTicketRejectReason();
      //console.log("selected status",selectedStatus);
      this.showRejectDropdown = selectedStatus?.is_reject || false;
      if (this.showRejectDropdown) {
        this.getTicketRejectReason();
      } else {
        this.ticket_reject_selected = null;
      }
    },

    async Get_ticketDetails() {
      var data = {
        ticket_no: this.product.ticket_no,
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
        lang:this.$i18n.locale
      

      };
      this.isLoadingModel = true;
      const response = await apis.getticketDetails(data); // Waits for the API response
      if (response.status === 200) {
        this.isLoadingModel = false;
        this.row_data = response.data.data;
        const latestComment = response.data.ticket_comments[response.data.ticket_comments.length - 1];

          if (latestComment && latestComment.status) {
            this.status_id = latestComment.status; // Use the status from the latest comment
           // console.log("commentstatus",this.status_id);
          } else {
            this.status_id = response.data.data[0].status; // Fallback to the status from the first ticket data
           //console.log("status",this.status_id);
          }
        
        this.comments=response.data.ticket_comments;
        console.log("comments",this.comments);
    }
    },
    async update_status(ticket_no, status, current_status) {
      if (!ticket_no || !status) {
        this.$swal("Error !!!");
      }
      this.$confirm.require({
        target: event.currentTarget,
        message:  this.$t('confirmTicketStatusChange'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          let details = {
            user_id: localStorage.getItem("id"),
            ticket_no: ticket_no,
            client_id: localStorage.getItem("client_id"),
            status: status._id,
            lang:this.$i18n.locale,
          };
          var promise = apis.updateTicketStatus(details);
          promise.then((response) => {
            this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
            timeStamp.ticket_status_selected = status
          });
        },
        reject: () => {
          this.ticket_status_selected = current_status
        }
      });

    },

    async update_priority(priority_name, priority, current_priority) {
      if (!priority_name || !priority) {
        this.$swal("Error !!!");

      }

      this.$confirm.require({
        target: event.currentTarget,
        message: this.$t('confirmTicketPriorityChange'),
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            let details = {
              "priority_id": this.priority_selected._id,
              "_id": this.product._id,
              lang:this.$i18n.locale
            };

            const response = await apis.updatepriority(details);
            this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
            this.priority_selected = priority;
          } catch (error) {
            this.$swal("An error occurred while updating priority.");
            console.error(error);
          }
        },
        reject: () => {
          this.priority_selected = current_priority;
        }
      });
    },



    // async update_priority(priority_name,priority,current_priority) {

    //   if (!priority_name || !priority) {
    //     this.$swal("Error !!!");
    //   }
    //   this.$confirm.require({
    //             target: event.currentTarget,
    //             message: 'Are you sure you want to change ticket Priority?',
    //             icon: 'pi pi-exclamation-triangle',
    //             accept: () => {
    //               let details = {
    //     "priority_id": this.priority_selected._id,
    //     "_id": this.rowdata._id
    //   }
    //   var promise = apis.updatepriority(details);

    //               promise.then((response) => {
    //                 this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
    //          timeStamp.ticket_status_selected=priority     });
    //       },
    //       reject:()=>{
    //         this.ticket_status_selected=current_priority
    //       }});

    // },


    async showvideo(url) {

      window.open(url, "_blank");
    },
    async show_attachment(url) {
      window.open(url);
    },
    async handleFileUpload(event, type, index) {
      var fileUp = event.files[0];
      var file = fileUp;
      this.file = file;
      var prfeix = await this.date_time_prefix();
      var filename = prfeix + "_" + file.name;
      this.file_data = {
        filePath: filename,
        contentType: file.type,
      };
      var promise = apis.upload_to_AWS(this.file_data);
      promise.then((response) => {
        axios
          .put(response.data.data, file, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": file.type,
            },
          })
          .then((response) => {
            if (type == 1) {
              this.row_data[0].ticket_comments[index]['edited_attachment'].push(
                "https://eticket-docs.s3.ap-south-1.amazonaws.com/" + filename
              );
            } else {
              this.attachments.push(
                "https://eticket-docs.s3.ap-south-1.amazonaws.com/" + filename
              );
              this.attachments_count = this.attachments_count + 1;

            }
          });
      });
    },
    async date_time_prefix() {
      // Get the current date and time
      const currentDateTime = new Date();
      // Format the date as YYYY_MM_DD
      const formattedDate = currentDateTime
        .toISOString()
        .split("T")[0]
        .replace(/-/g, "_");
      // Format the time as HH_mm_ss
      const formattedTime = currentDateTime
        .toTimeString()
        .split(" ")[0]
        .replace(/:/g, "_");
      // Construct the final string
      const resultString = `${formattedDate}_${formattedTime}`;
      return resultString;
    },
  },
};
</script>
<style scoped>
/* Timeline Item Styling */
/* Custom Styles for Left-Aligned Timeline */
.left-aligned-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Left align the timeline items */
}
.red-label {
  color: red;
}

.green-label {
  color: green;
}

.default-label {
  color: gray;
}

.custom-card {
  background-color: #f7f5f5;
  /* Light grey background */
  border: 1px solid #ccc;
  /* Border color */
  padding: -4rem;
  border-radius: 9px;
  /* Rounded corners */
}

.timeline-item {


  display: flex;
  align-items: right;
  justify-content: space-between;
  padding: 20px 25px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  min-height: 70px;
  flex-direction: column;
}

/* Avatar Styling */
/* .avatar-container {
  display: flex;
 
  margin-right: 16px;
} */

/* .avatar {
  position: absolute;
  top: 10px;
  border-radius: 5%;
  width: 10px;
  height: 10px;
  object-fit: cover;
} */

/* .avatar-fallback {
  display: flex;
  top: 10px;
  background-color: #cfcfcf;
  color: #fff;
  border-radius: 5%;
  width: 20px;
  height: 20px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
} */

/* Comment Content */
.comment-container {
  flex-grow: 1;
  margin-right: 20px;
}

.username {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  top: 10px;
}

.comment-text {
  font-size: 12px;
  color: #666;
  margin-top: 6px;
  /* text-align: justify; */
  justify-content: center;

}




.p-button-rounded.p-button-danger {
  border-radius: 50%;
  /* Ensures the button is circular */
  padding: 2px;
  /* Adjust padding for a smaller button */
  font-size: 12px;
  /* Adjust the font size for a smaller button */
  width: 20px;
  /* Set a fixed width */
  height: 20px;
  /* Set a fixed height */
  display: flex;
  /* Center the icon inside the button */
  justify-content: center;
  align-items: center;
}

.avatar-container {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Space between elements */
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-fallback {
  padding: 5px 10px;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-delete {
  margin-left: 110px;
}



#custom_card {
  border-top: 3px solid #6b719b;
}

.card_custom {
  border-bottom: 3px solid #6b719b;
}

.bg-success {
  background-color: #00b064 !important;
}

.bg-success2 {
  background-color: #00b0b0 !important;
}

.container-comment {
  display: flex;
  gap: 20px;
}

.column-comment {
  background-color: #d6e9d1;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.column-1 {
  flex: 3;
  position: relative;
}

.timeline {
  position: relative;
  border-left: 2px solid #666;
  padding-left: 20px;
  margin-top: 20px;
}

.timeline-entry {
  display: flex;
  align-items: flex-start;
}

.timeline-time {

  padding-right: 10px;
  color: #555;
  font-size: 14px;
  width: 120px;
  text-align: right;
  height: 100%;
}

.timeline-marker {
  width: 12px;
  height: 12px;
  background-color: #666;
  border-radius: 50%;
  position: relative;
  top: 5px;
  float: left;
  position: absolute;
  margin-top: 0;
  margin-left: -7px;
}

.timeline-comment {
  display: flex;
  position: relative;
  border-left: 2px solid #666;
  margin-top: 0px;
  margin-bottom: 0px;
  flex-grow: 1;
}

.comment-box {
  background-color: #fff;
  border: 0px solid #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  flex-grow: 1;
  margin-left: 10px;
  margin-bottom: 10px;
}

.comment-header {
  font-weight: bold;
  margin-bottom: 8px;
}

.comment-meta {
  font-size: 12px;
  color: #777;
  text-align: right;
}

.column-2 {
  flex: 1;
  height: 100%;
}

.glow {
  font-size: 80px;
  color: #fff;
  text-align: center;
  animation: glow 1s ease-in-out infinite alternate;
}

#b_delete:hover {
  background-color: red;
  color: white
}

#b_post:hover {
  background-color: white;
  color: #00b0b0 !important
}

.zoom:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
}

.bold-date {
  font-weight: bold;
}

.left-aligned-timeline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Left align the timeline items */
}
</style>